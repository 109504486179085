<template>
  <div>
    <b-card-code title="">
      <div class="row mb-1">
        <div class="col-lg-12 justify-content-center d-flex">
          <b-media>
            <b-media-body class="my-auto">
              <b-card-text class="font-small-3 mb-0">
                <h3>{{ work_id }} | {{ name }}</h3>
              </b-card-text>
            </b-media-body>
          </b-media>
        </div>
      </div>
    </b-card-code>
    <b-card-code title="">
      <div class="row mb-2">
        <div class="col-lg-4 justify-content-center d-flex">
          <b-media style="cursor:pointer" no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="primary"
              >
                <feather-icon
                  size="24"
                  icon="DollarSignIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Teljes összeg
              </b-card-text>
            </b-media-body>
          </b-media>
        </div>
        <div class="col-lg-4 justify-content-center d-flex">
          <b-media style="cursor:pointer" no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="danger"
              >
                <feather-icon
                  size="24"
                  icon="ChevronsDownIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ total_price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </h4>
              <b-card-text class="font-small-3 mb-0">
                Kiszámlázott összeg
              </b-card-text>
            </b-media-body>
          </b-media>
        </div>
        <div class="col-lg-4 justify-content-center d-flex">
          <b-media style="cursor:pointer" no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                variant="success"
              >
                <feather-icon
                  size="24"
                  icon="ChevronsUpIcon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ (this.price - this.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft
              </h4>
              <b-card-text class="font-small-3 mb-0">
                <span v-if="difference>0">Hátralévő összeg</span>
                <span v-else>Különbözet</span>
              </b-card-text>
            </b-media-body>
          </b-media>
        </div>
      </div>
    </b-card-code>
    <b-card-code title="">
      <div class="row mb-2">
        <div class="col-lg-12">
          <h4>{{company}} > {{customer}}</h4>
        </div>
      </div>
      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Name -->
          <div
            v-if="props.column.field === 'invoice_price'"
            class="text-nowrap"
          >
            <span>{{ parseInt(props.row.invoice_price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") }} Ft</span>
          </div>

          <!-- Column: Status -->
          <!--<span v-else-if="props.column.field === 'status'">
            <b-badge :variant="statusVariant(props.row.status)">
              {{ props.row.status }}
            </b-badge>
          </span>-->

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <!--<b-dropdown-item v-b-modal.changeStatusModal @click="changeStatus(props.row.id)">
                  <feather-icon
                    icon="CheckCircleIcon"
                    class="mr-50"
                  />
                  <span>Státusz állítás</span>
                </b-dropdown-item>-->
                <!--<b-dropdown-item @click="show(props.row.id)">
                  <feather-icon
                    icon="EyeIcon"
                    class="mr-50"
                  />
                  <span>Feladatok</span>
                </b-dropdown-item>-->
                <!--<b-dropdown-item @click="document(props.row.id)">
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                  />
                  <span>Dokumentum generálás</span>
                </b-dropdown-item>-->
                <b-dropdown-item @click="setInvoiceNumber(props.row.id)">
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Részszámla megadás</span>
                </b-dropdown-item>
                <b-dropdown-item @click="removeFromProject(props.row.id)">
                  <feather-icon
                    icon="XCircleIcon"
                    class="mr-50"
                  />
                  <span>Eltávolítás</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                Mutasd 1-től
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['25','50','100']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap ">-ig a {{ props.total }} elemet </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>
    <b-card-code v-for="(sub_coc, index) in coc" :key="index">
      <p class="font-weight-bolder">{{ index }} > {{ sub_coc[0].data.monolit }}</p>
      <table class="table">
        <tr>
          <td>Részszámla</td>
          <td>Teljesítés dátuma</td>
          <td>Végösszeg</td>
          <td class="text-center">Számlázva</td>
        </tr>
        <tr v-for="sub_coc_data in sub_coc" :key="sub_coc_data.id">
          <td v-if="(sub_coc_data.data.tig_number != -1)">{{ sub_coc_data.data.tig_number }}. részszámla</td>
          <td v-else>Végszámla</td>
          <td>{{ sub_coc_data.data.tig_date }}</td>
          <td>{{ numberWithCommas(sub_coc_data.data.price) }} Ft</td>
          <td class="text-center">
            <feather-icon
              v-if="sub_coc_data.data.paid == 1"
              style="cursor:pointer"
              @click="setPaid(sub_coc_data.id)"
              icon="CheckCircleIcon"
              size="26"
              class="text-success"
            />
            <feather-icon
              v-else
              style="cursor:pointer"
              @click="setPaid(sub_coc_data.id)"
              icon="MinusCircleIcon"
              size="26"
              class="text-danger"
            />
          </td>
        </tr>
      </table>
    </b-card-code>
    <b-modal id="setInvoiceNumber" title="Részszámla megadása" hide-footer>
      <b-row class="my-1 pl-1 pr-1">
        <div class="col-lg-12">
          <b-form-group
            label="Hanyadik részszámla?"
            label-for="invoice_number"
          >
            <b-form-input
              id="invoice_number"
              v-model="invoice_number"
              class="w-100"
            />
          </b-form-group>
          <p class="text-right"><buton @click="updateInvoice()" class="btn btn-primary">Módosítás</buton></p>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BPagination, BFormSelect, BDropdown, BDropdownItem, BAvatar, BMediaAside, BMediaBody, BMedia, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import axios from 'axios'

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BMedia,
    BFormGroup,
    BFormInput,
  },
  data() {
    return {
      pageLength: 25,
      selectedInvoiceID: null,
      dir: false,
      project_id: '',
      columns: [
        {
          label: 'Számla azonosító',
          field: 'invoice_number',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés névre',
          },
        },
        {
          label: 'Hanyadik részszámla',
          field: 'number',
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: 'Teljesítés kelte',
          field: 'fulfilment_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés teljesítés dátumára',
          },
        },
        {
          label: 'Kiállítás dátuma',
          field: 'created_in_rs3',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés kiállítás dátumára',
          },
        },
        {
          label: 'Fizetési határidő',
          field: 'invoice_date',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés fizetési határidő dátumára',
          },
        },
        {
          label: 'Végösszeg nettó',
          field: 'invoice_price',
          tdClass: 'text-right',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés összegre',
          },
        },
        {
          label: 'Pénznem',
          field: 'invoice_price_currency',
          filterOptions: {
            enabled: true,
            placeholder: 'Keresés pénznem vezetőre',
          },
        },
        {
          label: '',
          field: 'action',
        },
      ],
      rows: [],
      invoice_number: '',
      total_price: 0,
      difference: 0,
      coc: [],
      work_id: '',
      name: '',
      price: 0,
      searchTerm: '',
      selected: null,
      work_numberOptions: [],
      company: null,
      customer: null,
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        Folyamatban: 'light-info',
        Késésben: 'light-warning',
        Lejárt: 'light-danger',
        Kész: 'light-success',
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getInvoices()
    axios({
      method: 'get',
      url: `project/list/new_price/${this.$route.params.id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      if (res.data.work_number.substring(0, 2) === 'ME') {
        this.company = 'Monolit Épszer KFT'
      } else {
        this.company = 'Monolit Profi KFT'
      }
      this.price = res.data.price
      this.customer = res.data.customer
      this.name = res.data.name
      this.work_id = res.data.work_number
    })

    this.getCOC()
  },
  methods: {
    setPaid(id) {
      axios({
        method: 'put',
        url: `project/coc/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getCOC()
      })
    },
    setInvoiceNumber(id) {
      this.selectedInvoiceID = id
      this.$root.$bvModal.show('setInvoiceNumber')
    },
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getCOC() {
      axios({
        method: 'get',
        
        url: `project/${this.$route.params.id}/coc`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.coc = response.data
      })
    },
    removeFromProject(id) {
      axios({
        method: 'delete',
        
        url: `invoice/remove/project/${id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getInvoices()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen eltávolítva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$root.$emit('bv::hide::modal', 'setProject')
      })
    },
    updateInvoice() {
      axios({
        method: 'put',
        
        url: `invoice/set-invoice-number/${this.selectedInvoiceID}`,
        data: {
          invoice_number: this.invoice_number,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(() => {
        this.getInvoices()
        this.$root.$bvModal.hide('setInvoiceNumber')
        this.invoice_number = ''
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen frissítve',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
    getInvoices() {
      axios({
        method: 'get',
        
        url: `invoice/show/${this.$route.params.id}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(response => {
        this.rows = response.data
        this.total_price = this.rows.reduce((a, b) => +a + +(parseInt(b.invoice_price).toFixed() || 0), 0)
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
